import {
  CashierInitParams,
  DEFAULT_MIN_AMOUNT,
  defaultMaxAmountsMap,
} from '../../models';
import { InputMetaData } from '../../slices';
import { compareDecimals } from '../compareDecimals';

export const validateByCharactersType = (amount: string): boolean =>
  /^\d+(,\d{1,})?(\.\d{1,})?$/.test(amount);

export const validateByScale = (
  amount: string,
  params: InputMetaData,
): boolean => {
  const { scale } = params || {};
  if (typeof scale === 'undefined') return true;

  if (Math.sign(scale) === -1) {
    return Number(amount) % Math.pow(10, Math.abs(scale)) === 0;
  }

  if (Math.sign(scale) === 1) {
    const _amount = String(amount).split('.');

    if (_amount.length === 1) return true;

    return _amount[1].length <= Math.abs(scale);
  }

  if (Math.sign(scale) === 0) {
    return Math.floor(Number(amount)) === Number(amount);
  }
};

export const validateByLimits = (
  amount: string,
  min?: number,
  max?: number,
): boolean => {
  const preparedAmount = amount.replace(',', '.');
  if (min && max) {
    return (
      preparedAmount &&
      compareDecimals(preparedAmount, String(min)) >= 0 &&
      compareDecimals(preparedAmount, String(max)) <= 0
    );
  }

  if (min) {
    return preparedAmount && compareDecimals(preparedAmount, String(min)) >= 0;
  }

  if (max) {
    return preparedAmount && compareDecimals(preparedAmount, String(max)) <= 0;
  }

  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  const activeType = params.get(CashierInitParams.PaymentType);

  return (
    compareDecimals(preparedAmount, String(DEFAULT_MIN_AMOUNT)) >= 0 &&
    compareDecimals(preparedAmount, String(defaultMaxAmountsMap[activeType])) <= 0
  );
};
