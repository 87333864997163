export const compareDecimals = (a: string, b: string): 1 | 0 | -1 => {
  const [aInt, aDec = ''] = a.split('.');
  const [bInt, bDec = ''] = b.split('.');

  const aIntBig = BigInt(aInt);
  const bIntBig = BigInt(bInt);

  if (aIntBig < bIntBig) return -1;
  if (aIntBig > bIntBig) return 1;

  const maxLength = Math.max(aDec.length, bDec.length);
  const aDecBig = BigInt(aDec.padEnd(maxLength, '0'));
  const bDecBig = BigInt(bDec.padEnd(maxLength, '0'));

  if (aDecBig > bDecBig) return 1;
  if (aDecBig < bDecBig) return -1;

  return 0;
};
