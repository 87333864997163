import { createSelector } from '@reduxjs/toolkit';

import { AMOUNT_FIELD_NAME } from '../constants';
import { convertData } from '../helpers';
import { FieldType } from '../models';
import {
  PaymentMethodInput,
  selectActiveAccount,
  selectCashierConfig,
  selectPaymentInputs,
  selectPaymentMethod,
} from '../slices';

export const selectProcessingAttributes = createSelector(
  selectPaymentInputs,
  selectActiveAccount,
  selectPaymentMethod,
  selectCashierConfig,
  (fields, activeAccount, { preprocessor }, { amount }) => {
    const initialAttributes = activeAccount
      ? {
          amount: fields[AMOUNT_FIELD_NAME]?.value,
          accountId: activeAccount.value,
        }
      : { amount: fields[AMOUNT_FIELD_NAME]?.value };

    if (amount) {
      initialAttributes.amount = amount;
    }

    const getValue = (value: string, preprocessed: boolean): string => {
      const trimmedValue = typeof value === 'string' ? value.trim() : value;

      return preprocessed
        ? convertData(trimmedValue, preprocessor)
        : trimmedValue;
    };

    return Object.entries(fields).reduce((acc, [k, v]) => {
      const { value = null, type, name, params } = v as PaymentMethodInput;

      if ((!value && type !== FieldType.Select) || name === AMOUNT_FIELD_NAME)
        return acc;

      return {
        ...acc,
        [k]: getValue(value, params?.preprocessed || false),
      };
    }, initialAttributes);
  },
);
