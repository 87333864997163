import { getDeviceData } from '@finteqhub/sdk-js/dist/src/utils';
import { v4 as uuidv4 } from 'uuid';

import { PaymentMethodInput } from '@cashier/cashier-common';

const deviceData = {
  ...getDeviceData().device.browser,
  fingerprint: uuidv4().replaceAll('-', ''),
  type: getDeviceData().device.type,
  javaEnabled: String(getDeviceData().device.browser.javaEnabled),
  javaScriptEnabled: String(getDeviceData().device.browser.javaScriptEnabled),
};

export const FinteqHubSessionProcessor = {
  processSessionField: (field: PaymentMethodInput) => {
    return {
      ...field,
      value: deviceData[field.name],
      isValid: Boolean(deviceData[field.name]),
    };
  },
};
