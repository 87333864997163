export enum FieldType {
  Text = 'text',
  Number = 'number',
  Amount = 'amount',
  AccountId = 'accountId',
  Regex = 'regex',
  Integer = 'integer',
  Select = 'select',
  Email = 'email',
  DateTime = 'date_mm_yyyy',
  Cvv = 'cvv',
  CardCvc = 'card_cvc',
  Phone = 'phone',
  DynamicSelect = 'dynamic_select',
  CreditCardNumber = 'creditcard_number',
  CreditCardVisa = 'creditcard_visa',
  CardNumber = 'card_number',
  CreditCardMastercard = 'creditcard_mastercard',
  IdentificationNumber = 'identification_number',
  PayzenPayTmAccountNumber = 'payzen_paytm_account_number',
  PayzenImpsAccountNumber = 'payzen_imps_account_number',
  Hidden = 'hidden',
  /* specific types for Display container */
  Input = 'input',
  Url = 'url',
  Button = 'button',
  QrCode = 'qr_code',
  CopyableText = 'copyable_text',
  ExpirationDate = 'expirationDate',
  FileLink = 'file_link',
  FiatAmount = 'fiatAmount',
}
