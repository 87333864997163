export enum COMPONENTS_CONFIG_KEYS {
  InformationBlockShortenedViewMethods = 'InformationBlock.ShortenedViewMethods',
  InformationFieldRectangleQrFormMethods = 'InformationField.RectangleQrFormMethods',
  CopyableTextAlternativeViewMethods = 'CopyableText.AlternativeViewMethods',
  CopyableTextShowAsQrCodeMethodPrefixes = 'CopyableText.ShowAsQrCodeMethodPrefixes',
  CopyableTextShowAsQrCodeMethods = 'CopyableText.ShowAsQrCodeMethods',
}

export const CASHIER_CONFIGURATION: {
  [key in COMPONENTS_CONFIG_KEYS]: string[];
} = {
  [COMPONENTS_CONFIG_KEYS.InformationBlockShortenedViewMethods]: [
    'HeroPaymentsCryptoBTC',
    'HeroPaymentsCryptoBNB',
    'HeroPaymentsCryptoDOGE',
    'HeroPaymentsCryptoLTC',
    'HeroPaymentsCryptoTRX',
    'HeroPaymentsCryptoUSDT',
    'HeroPaymentsCryptoXRP',
    'HeroPaymentsCryptoBCH',
    'HeroPaymentsCryptoETH',
  ],
  [COMPONENTS_CONFIG_KEYS.InformationFieldRectangleQrFormMethods]: [
    'LocalPaymentRedDePagosHeyCostaRica',
    'LocalPaymentTeleDolarCostaRica',
    'LocalPaymentBancoGTContinentalGuatemala',
    'LocalPaymentCOAC29deOctubreEcuador',
    'LocalPaymentCOAVEcuador',
    'LocalPaymentCoopmegoEcuador',
    'LocalPaymentDIFCruzAzulEcuador',
    'LocalPaymentFacilitoEcuador',
    'LocalPaymentFarmacias911Ecuador',
    'LocalPaymentFarmaciasEconomicasEcuador',
    'LocalPaymentHOUSEEcuador',
    'LocalPaymentJardinAzuayoEcuador',
    'LocalPaymentMedicityEcuador',
    'LocalPaymentMiComisariatoEcuador',
    'LocalPaymentMutualistaPichinchaEcuador',
    'LocalPaymentRianxeriaEcuador',
    'LocalPaymentRNXEcuador',
    'LocalPaymentTiaEcuador',
    'LocalPaymentUnipuntosEcuador',
    'LocalPaymentWesternUnionEcuador',
    'LocalPaymentZonaPagoEcuador',
  ],
  [COMPONENTS_CONFIG_KEYS.CopyableTextAlternativeViewMethods]: [
    'HeroPaymentsCryptoXRP',
    'HeroPaymentsFiatXLM',
    'HeroPaymentsFiatRipple',
    'HeroPaymentsCustodyCryptoXRP',
    'ChangellyXRP',
    'ChangellyBCH',
    'ChangellyBTC',
    'ChangellyDOGE',
    'ChangellyETH',
    'ChangellyLTC',
    'ChangellyTON',
    'ChangellyTRX',
    'ChangellyUSDT',
    'ChangellyXRP',
  ],
  [COMPONENTS_CONFIG_KEYS.CopyableTextShowAsQrCodeMethodPrefixes]: [
    'HeroPaymentsCrypto',
    'HeroPaymentsFiat',
    'HeroPaymentsCustody',
    'Changelly',
  ],
  [COMPONENTS_CONFIG_KEYS.CopyableTextShowAsQrCodeMethods]: [
    'PagsmileChileMach',
    'CommerGatePIX',
    'PaykassmaUpiS',
    'SmartfastpayAccountPIX',
    'IPaguePIX',
    'BitoloPIX',
    'ProDataQRPH',
  ],
};
