import { PaymentMethodInput } from '@cashier/cashier-common';

export const handleSelectField = (properties: PaymentMethodInput) => {
  if (properties.params?.options.length === 1) {
    return {
      ...properties,
      isValid: true,
      value: properties.params.options[0].value,
    };
  }
  return {
    ...properties,
    isValid: false,
    value: null,
  };
};

export const handleAmountField = (paymentInputs, amount) => ({
  ...paymentInputs.amount,
  value: amount,
});

export const handleDefaultField = (properties: PaymentMethodInput) => ({
  ...properties,
  value: properties.value,
  isValid: false,
});
